.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #232628;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position: sticky;
  top: 0;
  width: 100%;

  a {
    text-decoration: none;
    
    h1 {
      color: rebeccapurple;
    }
  }


  & ul {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    list-style: none;
    text-align: left;
    width: 100%;

    & li a {
      color: #8864c7;
      text-decoration: none;
    }

   & li a:hover {
    color: #7e57c2;
   }
  }
}