.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(33, 35, 37);
  max-height: 300px;
  width: 400px;
  padding: 1rem;
  border-radius: 10px;

  span {
    font-style: italic;
  }

  .linkContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
   justify-content: center;
    gap: 10%;
  }
}