.about {
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: center;
  align-self: center;
  max-width: 70%;
  height: 100vh;
  padding: 0 1.25rem;

  .aboutContainer {
    flex: 1;
    p {
      font-size: 1.25rem;
    }
  }


  @media (max-width: 768px) {
    flex-direction: column;
    align-self: auto;
    height: auto;
    height: 50vh;
  }
}