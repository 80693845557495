.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-self: center;
  height: 80vh;
  padding: 0 1rem;
  max-width: 50%;
  font-size: 1.25rem;

  span {
    color: #7e57c2;
  }

  @media (max-width: 768px) {
    height: auto;
    margin-top: 25%;
    max-width: 100%;
    font-size: 1rem;
    padding: 0 2rem;
  }
}