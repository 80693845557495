* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  scroll-behavior: smooth;
  scroll-padding: 150px;
}

body {font-family: "Noto Sans", sans-serif;
  background-color: #2b2e30;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
